import React, { useState, useRef } from "react"
import { Box, Typography} from "@mui/material";
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import brasilmap from "../../assets/brasil-estados.json"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function MapChart() {
    const [tooltipContent, setTooltipContent] = useState([]);
    const [tooltipS, setTooltipS] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const divRef = useRef(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchesfh = useMediaQuery(theme.breakpoints.up('qhd'));

    const handleMouseEnter = (geo) => {
        setTooltipS(true);
        setTooltipContent( [geo.properties.name, geo.properties.clients]);
      
    };
    const handleMouseMove = (event) => {
       
        const { clientX, clientY } = event;
        const {top, left} = divRef.current.getBoundingClientRect();
        

        const mouseX = clientX+15;
        const mouseY = matchesfh?clientY-top-700:matchesMd?clientY-top-left+100:clientY-top-400 ;
        
        setMousePosition({ x: mouseX, y:mouseY});
    };
    /* eslint-disable */
    return (
        <>
            <ComposableMap
                
                projection="geoMercator"
                style={{
                    display:matches?'flex':'none',
                    marginTop:matches?'':'20px'
                }}
                projectionConfig={{
                    width: 100,
                    center: [-54, -15],
                    scale:810,
                }}
            >
                <Geographies sx={{background:"red"}} ref={divRef} geography={brasilmap}>
                    {({ geographies }) =>
                        geographies.map((geo) => (

                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                onMouseEnter={() => handleMouseEnter(geo)}
                                onMouseLeave={()=> setTooltipS(false)}
                                onMouseMove={handleMouseMove}
                                stroke=""
                                style={{
                                    default: { fill:geo.properties.clients!='0'?"#5B2E87":"rgb(181 158 203)", outline: 'none', transition:'fill 0.5s' },
                                    hover: { fill: "rgb(244, 63, 89)", outline: 'none', },
                                    pressed: { fill: "rgb(244, 63, 89)", outline: 'none' },
                                }}
                            />


                        ))
                    }
                </Geographies>
            </ComposableMap>

            {tooltipS && (
                <Box style={{
                    
                    marginTop:matches?mousePosition.y:'none', 
                    left:matches?mousePosition.x:'none', 
                    color:'white', 
                    position:matches?'absolute':"relative",
                    padding: '5px 10px',
                    borderRadius: '6px',
                    justifyContent: 'center',
                    textAlingn:'center',
                    alingnItens:"center",
                    display: 'flex',
                    background: 'rgba(0, 0, 0, 0.5)'
                    }}>
                    <Typography>{tooltipContent[0]}<br/>{tooltipContent[1]} Clientes</Typography>
                </Box>
            )}
        </>
    )
}