import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/home";

import { CssBaseline, useMediaQuery } from "@mui/material";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [colorMode, setColorMode] = useState(prefersDarkMode);
  useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("3"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: !colorMode ? "light" : "dark",
          primary: {
            main: !colorMode ? "#5B2E87" : "#A77AD2",
          },
          secondary: {
            main: "#e83750",
          },
          drawer: {
            main: !colorMode ? "#5B2E87" : "#121212",
            text: !colorMode ? "#E8E6E3" : "#BCBCBC",
          },
          background: {
            default: !colorMode ? "#F0F2F5" : "#141617", //EEF2F6
            paper: !colorMode ? "#fff " : "#121212",
            paperOutlined: !colorMode ? "#fff " : "#252525",
            paperChat: !colorMode ? "#FFFFFF" : "#353535",
          },
          layoutBackGround: { main: !colorMode ? "#5B2E87" : "#121212" },
          chip: {
            pending: !colorMode ? "#E83750" : "#A61328",
            inProgress: !colorMode ? "#ED6C02" : "#BE5602",
            completed: !colorMode ? "#2E7D32" : "#387F4D",
            waitingReview: !colorMode ? "#0288D1" : "#077CB1",
            waitingReturn: !colorMode ? "#5B2E87" : "#502876",
            arquive: !colorMode ? "#777777" : "#424242",
          },
          easyMon: {
            main: "#e84b21",
          },
          easyFlow: {
            main: "rgb(30, 170, 112)",
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            xsm: 300,
            xsp: 400,
            sm: 600,
            smx: 800,
            md: 900,
            mdx: 1000,
            lg: 1200,
            xl: 1536,
            fh: 1920,
            qhd: 2560,
          },
        },

        components: {
          MuiButton: {
            styleOverrides: {
              containedPrimary: colorMode && { backgroundColor: "#5B2E87" },
            },
          },
          MuiChip: {
            styleOverrides: {
              containedPrimary: true && { backgroundColor: "#5B2E87" },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: !colorMode ? "#5B2E87" : "#121212",
                color: "#fff",
              },
            },
          },
        },
      }),
    [colorMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
