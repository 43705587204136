export const navbarContentButtons={
  height: '100%',
  alignItems: 'center',
  display: { sm: "none", xs: "none", md: "block" },
 

}
export const navbarButtons={
  height: '100%',
  color: '#fff', 
  marginRight: "10px",
  '&:hover': {
    backgroundColor: "transparent",
    color:"secondary.main"
  },
}

export const buttonContact={
  my: 1, mx: 1.5, 
  width:'90%',
  maxWidth:"300px",
  margin:'0px 0px 20px 0px',
  fontFamily: 'Rubik',
  padding: '5px',
  textTransform:"none"
  
}

export const subtitleContact= {
  color: '#5B2E87!important',
  fontFamily: 'Rubik',
  fontWeight: '600',
  fontSize: "1.2rem",
  margin:'10px 0px -5px 0px'
};

export const body = {
  scrollbarWidth: 'thin !important',
  overflowX: 'hidden !important',
  scrollbarColor: 'transparent transparent'
};

export const bodyScrollbar = {
  width: '8px',
  background: 'transparent'
};

export const bodyScrollbarTrack = {
  background: 'transparent'
};

export const bodyScrollbarThumb = {
  backgroundColor: '#5B2E87',
  borderRadius: '10px'
};

export const filter = {
  top: '0',
  left: '0',
  position: 'absolute',
  width: '100%',
  height: '95%',
  backgroundColor: 'rgba(39, 0, 77, 0.41)'
};

export const line = {
  position: 'relative',
  width: '30%',
  height: '2px',
  backgroundColor: '#000',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const navBar = {
  boxShadow: 'none!important',
  backgroundColor: '#121212!important',
  marginTop: '10px',
  width:"80vw",
  minWidth:"300px",
  left:"auto",
  right:"auto",
  display: 'flex!important',
  marginLeft: "10vw",
  borderRadius: '10px',
  justifyContent: 'center',
  transition:'background-color 0.5s',
};

export const navBarTrans = {
  //background: 'transparent!important',
  backgroundColor:"#0c0b0b!important",
  backgroundImage: "none",
  boxShadow: 'none!important',
  //marginTop: '10px',
  display: 'flex!important',
  minWidth:"300px",
};


export const title = {
  color: '#FFF ',
  //fontFamily: 'Rubik',
  fontFamily:'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: '700',
  display: 'flex',
 
  fontSize: {xs:"2em",md:'3.1em'}
};

export const subtitleDestaque = {
  color:"#e83750",
  

}
export const subtitle = {
  color: '#FFF',
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: {mdx:'2rem', sx:"1rem"},
  display: 'flex',
  fontSize: {fh:'2em',qhd:'2.4em',xl:'1.9em',sm:'1.4em', xs:'1.1em'}
};

export const subsubtitle = {
  color: '#FFF',
  fontFamily: 'Rubik',
  fontWeight: '300',
  lineHeight: '1.3em',
  display: 'flex',

  fontSize: {xs:"0.95em",sm:"1.3em",md:'1.5em'}
};


export const contentSizeSection ={
  maxWidth:'2000px',
 

}
export const contentTitle = {
  fontFamily: 'Rubik',
  marginTop: {qhd:'35vh', xs:'22vh'},
  display: 'flex',
  
  flexDirection: 'column'
};

export const buttonTitle = {
  //padding:{xs:'10px 60px !important',sm:'18px 60px !important'} ,
  padding:"10px 20px",
  fontSize:"1.1rem",
  boxShadow:"0px 0px 8px red"
  
};

export const buttonTitle2 = {
  marginLeft: '20px !important',
  border: '2px solid white !important',
  color: 'white !important'
};

export const buttonLogin = {
  display: 'none !important'
  
};

export const contentPages = {
  width: '100%',
  padding: {xs:'10px 10vw', qhd:'10px 15vw'},
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: {mdx:'transparent',xs:"#ffffffef"},
  fontFamily: "Rubik",
};

export  const titleContent= {
  color: 'black',
  fontFamily: 'Rubik',
  fontSize: {xs:'1.4em',smx:'2em', qhd:'2.4em'},
  fontStyle: 'normal',
  fontWeight: '300',
  //marginTop:'10px',
  textAlign:'center',
  width:"100%",
};

export const mtitleContent = {
  marginTop: '20px',
  color: '#5B2E87',
  fontFamily: 'Rubik',
  fontWeight: '600',
  fontSize: {xs:'18px',smx:'20px', qhd:'25px'},
  textAlign:'center',
  width:"100%",
};

export const textAbout = {
  color: '#5B2E87',
  fontFamily: 'Rubik',
  fontSize: '1.2em',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '35px',
  letterSpacing: '0.5px',
  marginTop: '10px',
  justifyContent: 'center',
  textAlign: 'center'
};

export const titleInsignia = {
  fontFamily: 'Rubik !important',
  color: '#1b1b1b',
  fontSize: {xs:"1.2rem", fhd:'1.4rem',qhd: '1.6rem'},
  fontWeight: '400',
  width:'100%',
  textAlign:{xs:'center', lg:'left'}
};

export const textInsignia = {
  fontSize: {xs:"1rem", fhd:'1.2rem',qhd: '1.4rem'},
  fontFamily: 'Rubik !important',
  color: '#272627',
  width:'100%',
  fontWeight: '300',
  
  textAlign:{xs:'center', lg:'left'}
  
};

export const cardInsignia = {
  display: 'flex',
  overflow: 'hidden',
  marginTop: '10px'
};

export const iconInsignia = {
  color:"secondary.main",
  fontSize:{xs:'3rem', fh:'3.5rem',qhd:'4rem'},
  justifyContent:'center',
  alignItems:'center',
  margin:{xs:'auto', md:'0px'}
};

export const contentServices = {
  width: '100%',
  height: 'auto',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(to bottom, rgba(39, 0, 77, 1), #cccccc)'
};
export const textRubik300 ={
  fontFamily:'Rubik',fontWeight:"300",
}

export const titleServices = {
  fontFamily: 'Rubik !important',
  fontWeight: '400',
  textAlign:'center',
  fontSize: {xs:'20px !important', qhd:'25px !important'}
};

export const textServices = {
  textAlign:'center',
  fontFamily: 'Rubik !important',
  fontWeight: '300',
  fontSize: {xs:'15px !important', qhd:'24px!important' },
  padding: '15px',

  height:'100%',
  justifyContent:"center",
  alignItems:'center',
  display:'flex'
};

export const paperService = {
  position:'relative',
  color: 'white !important',
  background: 'transparent !important',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid white !important',
  transition:'margin-top 1s',
  height:'100%',
  marginTop:'0px',
  ':hover':{

   boxShadow:'5px 5px 15px 0px black'
  }
};

export const iconServices = {
  fontSize: '55px !important',
  marginTop: '20px',
  display: 'flex'
};

export const cardTextService = {

  margin: '10px',
  display:'flex',
  flex:1,
  background: 'rgba(255, 255, 255, 0.9) !important',
  color: 'black !important',
  height:'100%'
  
};

export const contentProducts = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '-30px'
};

export const contentProduct = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  
  
};

export const paperProducts = {
  
  padding: '10px 15px',
  borderRadius: '50px',
  backgroundColor: '#333 !important',
 
  height: "50px", 
  width: "auto" 
 
};

export const titleProduct = {
  fontSize: '30px !important',
  fontWeight: '300',
  margin: '0px 0px -15px 0px !important',
  fontFamily: 'Rubik !important',
  color: '#5b2e87!important'
};

export const paperProducts2 = {
  width: '300px',
  height: '300px',
  borderRadius: '50% !important',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  background: '#929292',
  zIndex: '0',
  position: 'relative'
};

export const textProducts = {
  mt:1,
  color: 'black',
  fontFamily: 'Rubik !important',
  fontWeight: '300',
  fontSize: {xs:"1rem", fhd:'1.3rem',qhd: '1.5rem'},
  lineHeight: '30px'
  
};

export const linev = {
  position: 'relative',
  width: '3px',
  height: {xs:'30vh',fh:'25vh'},
  backgroundColor: 'secondary.main',
  marginRight: '50px',
  marginLeft: '50px',
  display: 'flex'
};

export const textPrincing = {
  height: 'auto',
  width: 'auto',
  color: 'black',
  
  fontFamily: 'Rubik !important',
  fontWeight: '300',
  fontSize: {xs:"1rem", fhd:'1.3rem',qhd: '1.5rem'},
  textAlign: 'center',
  marginTop:'15px',
  marginBottom:'25px'
};
export const cardPrincing ={
  width:{smx:'400px', xs:'100%'},
  height:{smx:'450px', xs:'100%'},
  borderRadius:'7px',
  display:'flex',
  flexDirection:'column',
  //justifyContent:"space-between",
  alignItems:'center',
  fontFamily:"Rubik",
  background: 'rgba(255,255,255,0.9)',
  color:'black',
  ':hover':{
   boxShadow:'5px 5px 15px 0px black'
  },
  
  
}
export const titleCardPrice ={
  fontSize:'25px',
  fontFamily:'Rubik',
  width:'100%',
  textAlign:'center',
  padding:"10px",
  fontWeight: '300',
  marginTop:'15px',
}
export const before ={
    background: "#e83750",
    width: '110px',
    borderRadius: '15px',
    textAlign: 'center',
    fontSize: '13px',
    position: 'inherit',
    margin: 'auto',
    marginTop: '10px',
    marginBottom: '-20px',
    color: 'white',
    padding: '3px',
}
export const lineCardPrice ={
  fontSize:'20px',
  fontFamily:'Rubik',
  width:'100%',
  textAlign:'center',
  mt:'-15px',
  
}
export const span = {
  marginLeft:'5px',
	display:'flex',
	background:'#e83750',
	width: '6px',
	height: '6px',
	borderRadius:'30px',
	
}
export  const carouselFeedback = {
  width: '100% !important',
  mt:"-10px"
};

export  const cardFeedback = {
  maxWidth: '800px',
  height: {mdx:'300px', smx:"350px", xs:"400px"},
  background: 'transparent !important',
  boxShadow:'none!important',
  
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent:"center",
  textAlign: 'center',
  fontFamily: 'Rubik !important',
  color: '#000 ',
  marginBottom:'-20px'
};

export const item = {
    display: 'flex',
    flexDirection:{lg:'row',xs:'column'} ,
    marginTop:'30px',
    width:'100%',
    textTransform: 'none'
  };
  export const textItem = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft:{lg:'20px',xs:'0px'},
  };
  export const gridItem={
    display:'flex',
    justifyContent: 'center',
    alignItems: 'left',
    flexDirection:'column'
  }

