import React, { useState, useEffect } from 'react';
import {

    Box,
    
  } from "@mui/material";

const TextScramble = ({ phrases, color }) => {
  const [text, setText] = useState('');
  const chars = 'abcdefg';

  useEffect(() => {
    let frameRequest;
    let frame = 0;
    let queue = [];
    let resolve;

    const randomChar = () => chars[Math.floor(Math.random() * chars.length)];

    const update = () => {
      let output = '';
      let complete = 0;
      for (let i = 0, n = queue.length; i < n; i++) {
        let { from, to, start, end, char } = queue[i];
        if (frame >= end) {
          complete++;
          output += to;
        } else if (frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = randomChar();
            queue[i].char = char;
          }
          output += `<span class="dud">${char}</span>`;
        } else {
          output += from;
        }
      }
      setText(output);
      if (complete === queue.length) {
        resolve();
      } else {
        frameRequest = requestAnimationFrame(update);
        frame++;
      }
    };

    const setTextAnimation = (newText) => {
      const oldText = text;
      const length = Math.max(oldText.length, newText.length);
      queue = [];
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || '';
        const to = newText[i] || '';
        const start = Math.floor(Math.random() * 40);
        const end = start + Math.floor(Math.random() * 40);
        queue.push({ from, to, start, end });
      }
      cancelAnimationFrame(frameRequest);
      frame = 0;
      update();
      return new Promise((res) => (resolve = res));
    };

    const next = (counter) => {
      setTextAnimation(phrases[counter]).then(() => {
        setTimeout(() => next((counter + 1) % phrases.length), 2000);
      });
    };

    next(0);

    return () => {
      cancelAnimationFrame(frameRequest);
    };
  }, []);

  return <Box component={"span"} sx={{color:color, display: "-webkit-inline-box",textWrap: "nowrap",width: {xs:"185px",md:"270px", overflow:"hidden"}}}  dangerouslySetInnerHTML={{ __html: text }} />;
};



export default TextScramble;